'use client'

import { LoginFooter } from '@tebuto/layout/client/Footer'

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex flex-col min-h-dvh bg-gradient-to-tr lg:from-primary-500 lg:to-primary-700 bg-primary-600">
            <div className="mx-auto pt-12 lg:py-16 py-4 flex flex-grow justify-center">{children}</div>
            <LoginFooter />
        </div>
    )
}
